<template>
    <!-- 我的资源-摄影师-信息详情部分 -->
    <div class="lensman_info_box">
        <!-- 标签页部分 -->
        <el-tabs 
            type="border-card"
            v-model="infoTabs"
            @tab-click="handleClick">
            <el-tab-pane 
                label="我的资料" 
                name="1">
                <center-lensman-info-data
                    ref="infoForm"
                    :handle-avatar="handlePhotographerAvatar"
                    :handle-info="handleModifyPhotographer">
                    <template #tips>
                        <div style="color: #E6A23C">此作品仅在被认证为网红摄影师/城市首席摄影师/斜杠青年时于个人页面展示</div>
                    </template>
                </center-lensman-info-data>
            </el-tab-pane>
            <el-tab-pane 
                label="我的作品" 
                name="2"
                lazy>
                <center-member-works
                    :handle-submit="handleSave"
                    :handle-getworks="() => getMemberWorks(1)">
                </center-member-works>
            </el-tab-pane>
            <el-tab-pane 
                label="城市首席摄影师" 
                name="3"
                v-if="basic_data.top"
                lazy>
                <center-member-tag
                    edit-router="centerLensmanChief"
                    :list="work_list"
                    @handleDelete="handleDel">
                    <!-- 已上传提示 -->
                    <template #tipsMore>
                        <div class="member-tag-tips-more">
                            城市首席摄影师你好，了解更多
                            <el-link type="primary">
                                城市首席摄影师特权
                            </el-link>
                        </div>
                    </template>
                    <!-- 首席未上传 -->
                    <template #notingTitle>
                        <div>
                            城市首席摄影师你好，了解更多
                            <el-link type="primary">城市首席特权</el-link>
                        </div>
                    </template>
                    <template #notingTips>
                        <div>
                            啊哦，这里还没有任何东西呢，快去
                            <router-link :to="{name: 'centerLensmanChief'}">
                                上传
                            </router-link>
                            吧
                        </div>
                    </template>
                    <!-- 非首席 -->
                    <template #unbecomeTitle>
                        <div>
                            注：您需要通过您的订单量和好评，来达到这一从业者的认证，
                            <el-link type="primary">点击了解详情</el-link>
                        </div>
                    </template>
                    <template #unbecomeTips>
                        <div>
                            啊哦，你还不是城市首席摄影师，不能上传服务哦
                        </div>
                    </template>
                </center-member-tag>
            </el-tab-pane>
            <!-- v-if="basic_data.celebrity" -->
            <el-tab-pane 
                label="网红摄影师" 
                name="4"
                lazy>
                <center-member-tag
                    edit-router="centerLensmanCelebrity"
                    :list="work_list"
                    @handleDelete="handleDel">
                    <!-- 已上传提示 -->
                    <template #tipsMore>
                        <div class="member-tag-tips-more">
                            网红摄影师你好，了解更多
                            <el-link type="primary">
                                网红摄影师特权
                            </el-link>
                        </div>
                    </template>
                    <!-- 网红未上传 -->
                    <template #notingTitle>
                        <div>
                            网红摄影师你好，了解更多
                            <el-link type="primary">网红摄影师特权</el-link>
                        </div>
                    </template>
                    <template #notingTips>
                        <div>
                            啊哦，这里还没有任何东西呢，快去
                            <router-link :to="{name: 'centerLensmanCelebrity'}">
                                上传
                            </router-link>
                            吧
                        </div>
                    </template>
                    <!-- 非网红 -->
                    <template #unbecomeTitle>
                        <div>
                            注：如果您拥有独特的影像风格，拥有其他社交媒体或平台的粉丝基础，您可以上传您的专属服务予我们审核，
                            <el-link type="primary">点击了解详情</el-link>
                        </div>
                    </template>
                    <template #unbecomeTips>
                        <div>
                            啊哦，你还不是网红摄影师，不能上传服务哦
                        </div>
                    </template>
                </center-member-tag>
            </el-tab-pane>
            <el-tab-pane 
                label="斜杠青年" 
                name="5"
                v-if="basic_data.slasher"
                lazy>
                <center-member-tag
                    edit-router="centerLensmanSlash"
                    :list="work_list"
                    @handleDelete="handleDel">
                    <!-- 已上传提示 -->
                    <template #tipsMore>
                        <div class="member-tag-tips-more">
                            斜杠青年你好，了解更多
                            <el-link type="primary">斜杠青年特权</el-link>
                        </div>
                    </template>
                    <!-- 斜杠青年未上传标题 -->
                    <template #notingTitle>
                        <div>
                            斜杠青年你好，了解更多
                            <el-link type="primary">斜杠青年特权</el-link>
                        </div>
                    </template>
                    <template #notingTips>
                        <div>
                            啊哦，这里还没有任何东西呢，快去
                            <router-link :to="{name: 'centerLensmanSlash'}">
                                上传
                            </router-link>
                            吧
                        </div>
                    </template>
                    <!-- 非斜杠青年 -->
                    <template #unbecomeTitle>
                        <div>
                            注：您可以在此页面编辑您希望变现的才艺或作品，请仔细填写内容说明，后台人员会在三个工作日内对您的内容进行审核
                            <el-link type="primary">点击了解详情</el-link>
                        </div>
                    </template>
                    <template #unbecomeTips>
                        <div>
                            啊哦，你还不是斜杠青年，不能上传服务哦
                        </div>
                    </template>
                </center-member-tag>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
    data() {
        let infoTabs = this.$route.query.id || '1';
        return {
            // 面包屑导航参数
            breadcrumb: [
                {
                    path: "/center/lensman",
                    path_name: "我是摄影师",
                },
                {
                    path: "/center/lensman/info",
                    path_name: "新增个人资料",
                },
            ],
            // 标签页
            infoTabs
        }
    },
    computed: mapState({
        user_id: state => state.user.user_id,
        basic_data: state => state.user.basic_data,
        work_list: state => state.user.work_list
    }),
    components: {
        // 面包屑导航组件
        breadCrumb: () => import('@/components/breadCrumb'),
        // 资料组件
        centerLensmanInfoData: () => import("@/components/centerLensmanInfoData"),
        // 作品组件
        centerMemberWorks: () => import("@/components/centerMemberWorks"),
        centerMemberTag: () => import("@/components/centerMemberTag"),
    },
    methods: {
        ...mapMutations([
            "setWorksStyle",
            'setMemberGoodsList'
        ]),
        ...mapActions([
            "getBasicData",
            "getPhotographerInfo",
            "handlePhotographerAvatar",
            "handleModifyPhotographer",
            "getWorksStyle",
            "handleSaveWorksStyle",
            "getMemberWorks",
            'getMemberGoodsList',
            'deleteMemberGoods'
        ]),
        // 查
        getData(tab) {
            let userID = this.user_id;
            this.getBasicData();
            switch (tab) {
                case '2':
                    this.getWorksStyle(1)
                    break;
                case '3':
                    this.getMemberGoodsList({ userID, tagID: 1 });
                    break;
                case '4':
                    this.getMemberGoodsList({ userID, tagID: 2 });
                    break;
                case '5':
                    this.getMemberGoodsList({ userID, tagID: 3 });
                    break;
                default: 
                    this.getPhotographerInfo()
            }
        },
		
        handleClick(tab, event){ // 标签页切换
            let id = this.$route.query.id;
            if(tab.name != id){
                this.$router.push({
                    name: 'centerLensmanInfo',
                    query: {
                        id: tab.name
                    }
                })
            }
        },
        handleSave({styleIDs, serviceArea, briefInfo, address, position}){
            this.handleSaveWorksStyle({
                type: 1,
                styleIDs,
                serviceArea,
                briefInfo,
                address,
                position
            }).then((res) => {
                // this.$message({
                //     showClose: true,
                //     message: res.msg,
                //     type: res.type? "success": "error"
                // })
                this.$alert(res.msg, '提示', {
                    confirmButtonText: '确定'
                })
            })
        },
        handleDel(goodsID) {
            let userID = this.user_id,
                routeID = this.$route.query.id;
            this.$confirm('是否删除当前项？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
                return this.deleteMemberGoods({ userID, goodsID })
            }).then(res => {
                this.$message({
                    showClose: true,
                    message: res.msg,
                    type: res.type? 'success': 'error'
                })
                res.type && this.getData(routeID);
            }).catch(err => {
                console.log(err)
            })
            
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            let tab = to.query.id;
            vm.getData(tab)
        })
    },
    beforeRouteUpdate(to, from, next) {
        let tab = to.query.id;
        this.getData(tab)
        next();
    },
    beforeRouteLeave (to, from, next) {
        this.setWorksStyle({});
        this.setMemberGoodsList([]);
        next();
    }
}
</script>

<style scoped>
.member-tag-tips-more{
    color: #909399;
}
</style>
